import React from "react";
import { useProbabilityContext } from "../context/Probability";
import "./ProbabilitySection.css";

const routes = [
  { type: "single", name: "Единичное предсказание" },
  { type: "multiple", name: "Массовое предсказание" },
  { type: "train", name: "Дообучение модели" },
];

export const ProbabilitySection2: React.FC = () => {
  const { activeProbability, setActiveProbability } = useProbabilityContext();

  return (
    <div className="probability-section flex flex-col gap-6 lg:gap-10 py-12 lg:py-20 w-full">
      <div className="relative rounded-[2.5rem] overflow-hidden flex-1 shrink-0 z-0 text-white p-7 py-12 font-medium flex flex-col gap-12 bg-bg-quaternary">
        <img
          src="/green-ai-bg.jpg"
          className="absolute top-0 left-0 lg:left-[40%] z-[-1] h-auto w-full opacity-50 bg-black blur-[0.2rem]"
          alt="AI Molodca"
        />

        <p className="z-1 text-[2.5rem]">
          Наша модель ИИ прогнозирует вероятность получения студентами красного
          диплома, позволяя вам своевременно выявить и развивать потенциал
          лучших учащихся. Воспользуйтесь нашими инновационными решениями для
          создания среды, в которой студенты могут достигать максимальных
          результатов и выпускаться с отличием
        </p>
      </div>
      <div className="flex justify-between gap-6 lg:gap-10 flex-col lg:flex-row">
        {routes.map((route) => (
          <button
            key={route.name}
            className={`probability-section-button ${
              activeProbability === route.type
                ? "probability-section-button_active"
                : ""
            }`}
            onClick={() => setActiveProbability(route.type as never)}
          >
            {route.name}
          </button>
        ))}
      </div>
    </div>
  );
};
