import React, { PropsWithChildren, useCallback } from "react";

type Props = PropsWithChildren<{
  onDrop: (acceptedFiles: File[]) => void;
  accept?: string;
  maxSize?: number;
}>;

const validateFile = (
  file: File,
  maxSize = 60 * (2 * 2 ** 10),
  accept?: string
) => {
  console.log(file.size);
  const isValidSize = maxSize ? file.size <= maxSize : true;
  return isValidSize;
};

const DropFileWrapper = ({ onDrop, children, accept, maxSize }: Props) => {
  const onDropCallback = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      const files = Array.from(event.dataTransfer.files).filter((item) =>
        validateFile(item, maxSize, accept)
      );
      onDrop(files);
    },
    [onDrop, accept, maxSize]
  );

  const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  return (
    <div
      className="relative w-full"
      onDrop={onDropCallback}
      onDragOver={onDragOver}
    >
      <input
        type="file"
        onChange={(event) => {
          const files = Array.from(event.target.files || []).filter((item) =>
            validateFile(item, maxSize, accept)
          );
          onDrop(files);
        }}
        accept={accept}
        className="opacity-0 absolute top-0 left-0 h-full w-full z-[9999]"
      />
      {children}
    </div>
  );
};

export default DropFileWrapper;
