import React from "react";
import { Tabs } from "@consta/uikit/Tabs";
import { Link } from "react-router-dom";
import { useTabsContext } from "../context/Navigation";
import "./Header.css";
const routes = [
  { path: "/", type: "marks", name: "Оценки" },
];

const getItemLabel = (item: (typeof routes)[0]) => item.name;

export const Header: React.FC = () => {
  const { setActiveTab } = useTabsContext();

  return (
    <header className="bg-bg-ternary bg-opacity-50 rounded-[2rem] p-4 flex flex-col gap-8 2 2xl:flex-row justify-between items-center overflow-hidden w-full">
      <Link
        className="text-[6rem] font-medium text-white relative rounded-[2.5rem] px-3 overflow-hidden z-0 shrink-0"
        to="/"
        onClick={() => setActiveTab("marks")}
      >
        <img
          src="/green-ai-bg.jpg"
          className="absolute top-0 left-0 z-[-1]"
          alt="AI Molodca"
        />
        <h1 className="z-1">AI Molodca</h1>
      </Link>

      <Tabs
        className="w-fit text-[3rem] flex"
        onChange={console.log}
        items={routes}
        getItemLabel={getItemLabel}
        view="clear"
        fitMode="scroll"
      />
    </header>
  );
};
