import React, { useEffect, useState } from "react";
import { FileField } from "@consta/uikit/FileField";
import { Text } from "@consta/uikit/Text";
import "./MultipleForm.css";
import DropFileWrapper from "../../../components/DropFileWrapeer";
import { Select } from "@consta/uikit/Select";
import { Pie } from "@consta/charts/Pie";

const disciplines = [
  "ФИЗИКО-МАТЕМАТИЧЕСКИЕ НАУКИ",
  "БИОЛОГИЧЕСКИЕ НАУКИ",
  "ХИМИЧЕСКИЕ НАУКИ",
  "НАУКИ О ЗЕМЛЕ",
  "ЯЗЫК",
  "ОБЩЕСТВЕННЫЕ КОММУНИКАЦИИ МЕДИА И ЖУРНАЛИСТИКА",
  "ИСТОРИЧЕСКИЕ НАУКИ",
  "ФИЛОСОФИЯ ЭТИКА И РЕЛИГИОВЕДЕНИЕ",
  "ТЕОЛОГИЯ",
  "ВОСТОКОВЕДЕНИЕ И АФРИКАНИСТИКА",
  "ОБРАЗОВАНИЕ И ПЕДАГОГИЧЕСКИЕ НАУКИ",
  "ФИЗИЧЕСКАЯ КУЛЬТУРА И СПОРТ",
  "ЭКОНОМИКА БИЗНЕС И УПРАВЛЕНИЕ",
  "ПОЛИТИКА СОЦИОЛОГИЯ И МЕЖДУНАРОДНЫЕ ПРОЦЕССЫ",
  "ЮРИСПРУДЕНЦИЯ",
  "ЭНЕРГЕТИКА ЭНЕРГЕТИЧЕСКОЕ МАШИНОСТРОЕНИЕ И ЭЛЕКТРОТЕХНИКА",
  "УПРАВЛЕНИЕ В ТЕХНИЧЕСКИХ СИСТЕМАХ",
  "ПРИКЛАДНАЯ ГЕОЛОГИЯ ГОРНОЕ ДЕЛО НЕФТЕГАЗОВОЕ ДЕЛО ГЕОДЕЗИЯ И ЗЕМЛЕУСТРОЙСТВО",
  "ФУНДАМЕНТАЛЬНАЯ ИНФОРМАТИКА И МАТЕМАТИЧЕСКОЕ ОБЕСПЕЧЕНИЕ КОМПЬЮТЕРНЫХ НАУК",
  "ИНФОРМАТИКА ВЫЧИСЛИТЕЛЬНАЯ ТЕХНИКА И ИСКУССТВЕННЫЙ ИНТЕЛЛЕКТ",
  "ГОСТЕПРИИМСТВО СЕРВИС И ОКАЗАНИЕ УСЛУГ",
  "СОЦИАЛЬНАЯ СФЕРА",
  "КЛИНИЧЕСКАЯ МЕДИЦИНА",
  "ФАРМАЦИЯ",
];

type Props = {};

type GroupName = string;

type Year_Semester = string;

type StudentId = string;

type DisciplineIndex = number;

type Mark = number;

type PredictionsResponse = {
  prediction_map: Record<
    GroupName,
    Record<Year_Semester, Record<StudentId, [DisciplineIndex, Mark][]>>
  >;
  xlsx_file_path: string;
};

type Item = { type: string; value: number };

function sum(array?: Item[]) {
  if (!array) {
    return "0";
  }
  let s: number = 0;
  for (const item of array) {
    s += item.value;
  }
  return s.toString();
}

export const MultipleForm = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const [activeGroup, setActiveGroup] = useState<any>({
    label: "МИВТ-17-2",
    id: "МИВТ-17-2",
  });
  const [selectedYearSemester, setSelectedYearSemester] = useState<
    string | null
  >("");
  const [results, setResults] = useState<PredictionsResponse | null>(null);

  const groupsOptions = Object.keys(results?.prediction_map || {}).map(
    (key) => ({ label: key, id: key })
  );

  useEffect(() => {
    setSelectedYearSemester(
      Object.keys(results?.prediction_map?.[activeGroup?.id || ""] || {})?.[0]
    );
  }, [activeGroup, results]);

  const avgMarksByTypesData = Object.values(
    results?.prediction_map?.[activeGroup?.id || ""]?.[
      selectedYearSemester || ""
    ] || {}
  ).reduce(
    (acc, cur) => {
      const { count, sum } = cur.reduce(
        (acc, [_, mark]) => {
          if (mark) {
            acc.count++;
            acc.sum += mark;
          }

          return acc;
        },
        { count: 0, sum: 0 }
      );

      const avg = sum / count || 0;

      if (!avg) {
        return acc;
      }

      acc.full++;

      if (avg >= 4.5) {
        acc.best++;
      } else if (avg >= 3.5) {
        acc.good++;
      } else if (avg >= 2.5) {
        acc.middle++;
      } else {
        acc.bad++;
      }

      return acc;
    },
    { full: 0, best: 0, good: 0, middle: 0, bad: 0 }
  );

  const chartData = [
    {
      type: "Отличная успеваемость",
      value: (avgMarksByTypesData.best / avgMarksByTypesData.full) * 100,
    },
    {
      type: "Хорошая успеваемость",
      value: (avgMarksByTypesData.good / avgMarksByTypesData.full) * 100,
    },
    {
      type: "Удовлетворительная успеваемость",
      value: (avgMarksByTypesData.middle / avgMarksByTypesData.full) * 100,
    },
    {
      type: "Неудовлетворительная успеваемость",
      value: (avgMarksByTypesData.bad / avgMarksByTypesData.full) * 100,
    },
  ].filter((i) => i.value);

  const handleFileUpload = async (file: File) => {
    setLoading(true);
    setResults(null);
    setActiveGroup("");
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await fetch("/api/v1/predict-mass/marks-for-teacher", {
        method: "POST",
        headers: {
          accept: "application/json",
        },
        body: formData,
      });

      if (response.status !== 200) {
        return;
      }

      const data = await response.json();

      setResults(data);
      const activeGroup = Object.keys(data.prediction_map)[0]
      setActiveGroup({ label: activeGroup, id: activeGroup });
    } catch (error) {
      console.error("Error uploading file:", error);
    } finally {
      setLoading(false);
    }
  };

  const getColorForValue = (value: number): string => {
    if (value >= 4.5) {
      return "#00FF00"; // Зеленый
    } else if (value >= 3.5) {
      return "#FFFF00"; // Желтый
    } else if (value >= 2.5) {
      return "#FFA500"; // Оранжевый
    } else {
      return "#FF0000"; // Красный
    }
  };

  const renderTable = () => {
    if (
      !results ||
      !selectedYearSemester ||
      !results.prediction_map?.[activeGroup.id]?.[selectedYearSemester]
    )
      return null;

    const groupData =
      results.prediction_map[activeGroup.id][selectedYearSemester];
    const firstStudentMarks = Object.values(groupData)[0];
    const disciplineNames = firstStudentMarks.map(([disciplineIndex]) => ({
      name: disciplines[disciplineIndex],
      index: disciplineIndex,
    }));

    return (
      <div
        className="grid gap-4 overflow-auto mt-16"
        style={{
          gridTemplateColumns: `repeat(${
            2 + disciplineNames.length
          }, minmax(300px, 1fr))`,
        }}
      >
        <div className="col-span-1 row-span-2 font-bold">Студент ID</div>
        <div className="col-span-1 row-span-2 font-bold">
          Общая успеваемость
        </div>
        <div
          className="font-bold text-center"
          style={{ gridColumn: `span ${disciplineNames.length}` }}
        >
          Группы дисциплин
        </div>
        {disciplineNames.map((discipline) => (
          <div key={discipline.index} className="text-center">
            {discipline.name}
          </div>
        ))}

        {Object.entries(groupData).map(([studentId, marks]) => {
          const averageMark =
            marks.reduce((sum, [, mark]) => sum + mark, 0) / marks.length;

          return (
            <React.Fragment key={studentId}>
              <div className="col-span-1">{studentId}</div>
              <div
                className="col-span-1 text-right  px-4 text-black font-bold"
                style={{ backgroundColor: getColorForValue(averageMark) }}
              >
                {averageMark.toFixed(2)}
              </div>
              {marks.map(([disciplineIndex, mark]) => (
                <div
                  key={disciplineIndex}
                  className="text-right px-4 text-black font-bold"
                  style={{ backgroundColor: getColorForValue(mark) }}
                >
                  {mark.toFixed(2)}
                </div>
              ))}
            </React.Fragment>
          );
        })}
      </div>
    );
  };

  const yearSemesterOptions = Object.keys(
    results?.prediction_map[activeGroup.id] || {}
  );

  const typeColors: Record<string, string> = {
    "Отличная успеваемость": "#00FF00", // Зеленый
    "Хорошая успеваемость": "#FFFF00", // Желтый
    "Удовлетворительная успеваемость": "#FFA500", // Оранжевый
    "Неудовлетворительная успеваемость": "#FF0000", // Красный
  };

  return (
    <div className="flex flex-col gap-6 lg:gap-10 py-12 lg:py-20 w-full">
      <div className="relative rounded-[2.5rem] overflow-hidden flex-1 shrink-0 z-0 text-white p-7 py-12 font-medium flex flex-col gap-12 bg-bg-quaternary">
        <img
          src="/green-ai-bg.jpg"
          className="absolute top-0 left-0 lg:left-[40%] z-[-1] h-auto w-full opacity-50 bg-black blur-[0.2rem]"
          alt="AI Molodca"
        />

        <h2 className="z-1 text-[4rem] leading-[4.01rem]">
          Перед загрузкой файла ознакомьтесь с нашим шаблоном, чтобы убедиться,
          что ваши данные соответствуют необходимым требованиям.{" "}
        </h2>
        <a
          className="multiple-form-button"
          href="/api/v1/predict-mass/marks-from-xlsx/example"
          download="example.csv"
        >
          Скачать файл
        </a>
      </div>

      <FileField
        id="file"
        accept=".csv,.xls,.xlsx"
        onChange={(e: any) => handleFileUpload(e?.target?.files?.[0])}
      >
        {(props) => (
          <DropFileWrapper
            onDrop={(file) => {
              handleFileUpload(file[0]);
            }}
            accept=".csv,.xls,.xlsx"
          >
            <div
              {...props}
              className="w-full h-fit p-10 flex flex-col gap-10 text-center text-white bg-[#D0FFD2] bg-opacity-50 rounded-[40px]"
            >
              {!loading && (
                <>
                  {" "}
                  <strong className="font-semibold text-xl">
                    Нажмите или перенесите файлы сюда, для загрузки
                  </strong>
                  <span className="text-lg">
                    Максимальный размер файла 50Мб
                  </span>
                  <span className="text-lg">Форматы: .csv, .xlsx</span>
                </>
              )}

              {loading && <p>Файл обрабатывается, пожалуйста, подождите...</p>}
            </div>
          </DropFileWrapper>
        )}
      </FileField>

      {results && (
        <div className="result-section flex flex-col gap-6 lg:gap-10 py-12 lg:py-20 w-full">
          <div className="font-medium text-[1rem] md:text-[2rem] bg-bg-ternary text-white shadow-xl p-16 rounded-[2rem] bg-opacity-50 flex flex-col gap-8">
            <Select
              label="Группа:"
              className="shrink-0 max-w-[660px] min-w=[300px] w-[100%] small-label"
              labelPosition="left"
              items={groupsOptions}
              value={activeGroup}
              onChange={setActiveGroup}
              size="l"
            />
            <Select
              label="Год / Семестр:"
              className="shrink-0 max-w-[660px] min-w=[300px] w-[100%] small-label"
              labelPosition="left"
              items={yearSemesterOptions}
              value={selectedYearSemester}
              onChange={setSelectedYearSemester}
              getItemLabel={(i) => i}
              getItemKey={(i) => i}
              size="l"
            />

            <div className="flex flex-col 3xl:flex-row w-full gap-16 justify-between">
              {renderTable()}

              <div className="flex flex-col items-center gap-16 bg-slate-900 p-16 bg-opacity-10 rounded-[4rem]">
                <Pie
                  style={{
                    width: 600,
                    height: "600",
                  }}
                  legend={false}
                  data={chartData}
                  angleField="value"
                  colorField="type"
                  color={(data) => typeColors[data.type as any] || "#FFFFFF"}
                  statistic={{
                    title: {
                      formatter: (v) => v?.type.split(" ")[0] || "Всего",
                      style: {
                        color: "white",
                      },
                    },
                    content: {
                      customHtml: (v, v2, v3, v4) => (
                        <Text
                          size="xl"
                          view="primary"
                          lineHeight="m"
                          color="#fff"
                        >
                          {v3?.value || sum(v4)}
                        </Text>
                      ),
                    },
                  }}
                  innerRadius={0.64}
                  label={{
                    type: "inner",
                    offset: "-50%",
                    content: "{value}",
                    style: {
                      textAlign: "center",
                      fontSize: 24,
                    },
                  }}
                  interactions={[
                    { type: "element-selected" },
                    { type: "element-active" },
                    { type: "pie-statistic-active" },
                  ]}
                />
                <div className="w-full flex flex-col gap-8">
                  {chartData.map((item) => (
                    <div className="flex gap-8 w-full justify-between">
                      <div>{item.type}:</div>
                      <div>{`${item.value}%`}</div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <p className="text-lg">
              Чтобы увидеть полный результат, скачайте файл.
            </p>
            <a
              className="multiple-form-button text-lg"
              href={`${results.xlsx_file_path}`}
              download="result.xlsx"
            >
              Скачать документ с оценками
            </a>
          </div>
        </div>
      )}
    </div>
  );
};
